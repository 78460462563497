.signup-container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.signup-backgroundImage {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
}

#PhoneInput{
  height: 3rem;
  width:100%;
}



.signup-backgroundImage img {
  width: 100%;
  height: 110%;
}

.signup-card-div {
  display: grid;
  gap: 1rem;
  width: 100%;
  width: 400px;
  padding: 2rem;
  background-color: transparent;
  border-radius: 0.5rem;
}

.signup-card-div p,
.signup-card-div h2 {
  margin: 0;
}

.signup-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.signup-logo {
  width: 5rem;
  height: 5rem;
}

.signup-logo img {
  width: 100%;
}

.signup-logo-container h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.signup-logo-container p {
  font-size: 0.875rem;
  color: #9ca3af;
}

.signup-input-group {
  position: relative;
  margin-bottom: 0.5rem;
}

.signup-input-group input {
  width: 90%;
  padding: 0.5rem 0rem 0.5rem 2.5rem;
  height: 1.8rem;
  border-radius: 0.5rem;
  color: #2c2c2c;
  font-size: 0.875rem;
}

.input-group input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.toggle-password {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
}

.signup-card-div form {
  display: grid;
  gap: 1rem;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keep-signed-in {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #9ca3af;
}

.keep-signed-in input {
  margin-right: 0.5rem;
}

.forgot-password {
  font-size: 0.875rem;
  color: #3b82f6;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.sign-in-button,
.sign-up-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.confirm-btn {
  width: 100%;
  padding: 0.5rem;
  background-color: #3b82f6;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  height:50px;
  transition: background-color 0.2s;
}
.confirm-btn:hover{
  background-color: #2563eb;
}

.sign-in-button:hover,
.sign-up-button:hover {
  background-color: #2563eb;
}

.sign-in-button:disabled,
.sign-up-button:disabled {
  background-color: #6b7280;
  cursor: not-allowed;
}

.google-sign-in {
  width: 100%;
  padding: 0.75rem;
  background-color: #ffffff;
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #4b5563;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-sign-in svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.google-sign-in:hover {
  background-color: #f3f4f6;
}

.login-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: white;
}

.login-link a {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.error-message {
  text-align: center;
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.or-divider::before,
.or-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}
.or-divider span {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}