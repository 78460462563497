/* General layout for the entire page */
.plans-page {
  display: flex;
  min-height: 100vh;
  background-color: #f4f7f9; /* Light background */
}

/* Side menu styling */
.side-menu {
  background-color: #003366; /* Dark blue */
  color: white;
  width: 220px;
  height: 100vh;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.side-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.side-menu li {
  padding: 12px 20px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #007b5e; /* Green */
  border-radius: 5px;
  transition: background-color 0.2s ease;
  font-weight: bold;
}

.side-menu li:hover {
  background-color: #005f4a; /* Darker green on hover */
}

/* Plans container styling */
.plans-container {
  margin-left: 240px; /* To make space for the side menu */
  padding: 40px 20px;
  width: 100%;
}

h2 {
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
}

/* Plans list */
.plan-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual plan item */
.plan-item {
  background-color: #e0f7e0; /* Light green */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.plan-item:hover {
  transform: scale(1.05);
}

.plan-item h3 {
  color: #007b5e;
  font-size: 18px;
  margin-bottom: 10px;
}

.plan-item p {
  color: #003366;
  margin: 5px 0;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .plans-container {
    margin-left: 0;
    padding: 20px;
  }

  .side-menu {
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
  }

  .side-menu li {
    margin: 5px 0;
    padding: 10px;
  }

  .plan-list {
    grid-template-columns: 1fr;
  }
}
