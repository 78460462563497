/* SideMenu.css */
.side-menu {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .side-menu.open {
    transform: translateX(0);
  }
  
  .menu-toggle {
    display: none;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: -40px;
    background-color: #34495e;
    padding: 10px;
    border-radius: 5px;
  }
  
  .side-menu nav ul {
    list-style: none;
    padding: 0;
  }
  
  .side-menu nav ul li {
    margin-bottom: 20px;
  }
  
  .side-menu nav ul li a ,.logoutBtn{
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }
  
  .side-menu nav ul li a.active-link,
  .side-menu nav ul li a:hover ,
  .logoutBtn:hover{
    background-color: #34495e;
  }
  
  .logoutBtn{
    margin-top: 50px;
    cursor: pointer;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
    .side-menu {
      position: absolute;
      left: 0px;
      top: 0;
      bottom: 0;
      transform: translateX(-100%);
    }
  
    .side-menu.open {
      transform: translateX(0);
    }
  
    .menu-toggle {
      display: block;
    }
  }
  