.checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.checkout-content {
  display: flex;
  gap: 40px;
  margin-top: 20px;
}

.payment-form,
.plan-details {
  flex: 1;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
}

h1,
h2 {
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.form-group input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.card-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.card-icon {
  width: 50px;
  height: 30px;
  background-color: #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666;
}

.plan-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.plan-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.promo-code {
  display: flex;
  margin-bottom: 20px;
}

.promo-code input {
  flex-grow: 1;
  margin-right: 10px;
}

.promo-code button,
.pay-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 20px;
}

.terms {
  font-size: 12px;
  color: #666;
  margin-top: 20px;
}

.error-banner {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
}

.error-banner > div {
  width: 1.2rem;
  height: 1.2rem;
}

.error-banner > div img {
  width: 100%;
}

@media (max-width: 768px) {
  .checkout-content {
    flex-direction: column;
  }
}
