.card-details {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.pay-button {
  background-color: #2c75ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #1a5bb8;
}
