.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.forgot-password-card {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 0.5rem;
}

.forgot-password-card form {
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.icon-background {
  width: 4rem;
  height: 4rem;
  background-color: #3b82f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  color: white;
}

.error-message {
  text-align: center;
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.success-message {
  text-align: center;
  color: #27FF82;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.forgot-password-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: white;
  margin-bottom: 0.5rem;
}

.forgot-password-text {
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;
}

.forgot-password-input {
  width: 90%;
  padding: 0.5rem 0rem 0.5rem 2.5rem;
  height: 1.8rem;
  border-radius: 0.5rem;
  color: #2c2c2c;
  font-size: 0.875rem;
}

.forgot-password-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.forgot-password-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.forgot-password-button:hover {
  background-color: #2563eb;
}

.floating-logo-div {
  position: relative;
}

.floating-logo-div img {
  position: absolute;
  top: 50%;
  left: 2.5%;
  transform: translateY(-50%);
}
