/* HomePage.css */
.home-page {
    display: flex;
    min-height: 100vh;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
  }
  