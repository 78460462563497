.pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .pricing-plans {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .plan {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .premium-plan {
    background-color: #3377FF;
    color: white;
  }
  
  .most-popular {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: #3377FF;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  h2 {
    margin-top: 0;
  }
  
  .price {
    font-size: 1.5em;
    font-weight: bold;
    margin: 10px 0;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
  }
  
  button {
    background-color: #3377FF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .premium-plan button {
    background-color: white;
    color: #3377FF;
  }
  
  button:hover {
    background-color: #2255CC;
  }
  
  .premium-plan button:hover {
    background-color: #f0f0f0;
  }
  
  @media (max-width: 768px) {
    .pricing-plans {
      flex-direction: column;
      align-items: center;
    }
  
    .plan {
      width: 100%;
      max-width: 300px;
    }
  }