.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff;
  color: #333;
  text-align: center;
}

.success-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.success-container p {
  font-size: 1.2rem;
  color: black;
}
